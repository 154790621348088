<template>
	<div class="transmit_sub_container">
		<div class="transmittal_slip_filter adj_sur">
			<div class="label_element_cover">
				<label>Filter By Date</label>
				<flat-pickr class="date_for_pos_fixed" v-model="daterange" :config="configDate" placeholder="Date"
					name="birthdate" @on-close="dateChanged" @on-open="cleardatepicker"></flat-pickr>
			</div>
			<div class="label_element_cover">
				<label>Filter By Response Type</label>
				<select v-model="response_type" @change="filterStatus">
					<option value="all">All</option>
					<option value="success">Success</option>
                    <option value="failure">Failure</option>
				</select>
			</div>
		</div>

		<section class="print_hide">
			<div class="two_tabs">
				<a class="tab_buttons active">
					INTEGRATION GATEWAYLOGS
					<span class="label">24</span>
				</a>
			</div>
			<div class="jobs_table">
				<table>
					<tr>
						<th>DATE</th>
						<th>PAYLOAD</th>
						<th>RESPONSE</th>
						<th>TYPE</th>
					</tr>
					<tbody v-if="!loading">
						<tr v-for="(data, i) in logs" :key="i">
							<td style="width: 100px; text-align: left;">
								<p class="text-center">{{ moment(data.created_at).format("DD-MM-YYYY") }}</p>
							</td>
							<td style="width: 300px">
								<p><code>{{ data.payload }}</code></p>
							</td>
                            <td style="width: 300px; overflow-wrap: anywhere;">
								<p><code>{{ data.response }}</code></p>
							</td>
							<td style="width: 100px">
								<p>{{ data.type }}</p>
							</td>
						</tr>
					</tbody>
				</table>
				<p v-if="!loading && logs.length == 0">No data found</p>
			</div>
			<loader v-if="loading" />
			<paginate v-if="pages > 0" v-show="!loading" v-model="current_page" :page-count="pages"
				active-class="paginate-active" :click-handler="fetchTransaction" prev-link-class="paginate-previous"
				next-link-class="paginate-next" :prev-text="'Prev'" :next-text="'Next'" :container-class="'pagination'"
				:no-li-surround="true">
			</paginate>
		</section>

		<div class="black-overlay" v-if="popupdatestatus">
			<div class="box-modal smallPops">
				<div class="body">
					<popUpdatePostalCount @closeModal="popupdatestatus = ''" :data="popupdatestatus"
						v-if="popupdatestatus" />
				</div>
			</div>
		</div>

	</div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

export default {
	name: "ReportIntegrationGatewayLogs",
    components: {
		flatPickr,
	},
	data() {
		return {
			logs: [],
			pages: 0,
			current_page: 0,
            daterange: moment(new Date()).format('YYYY-MM-DD'),
            configDate: {
				mode: "range",
				format: "d-m-Y",
				altFormat: "d-m-Y",
				altInput: true
			},
			response_type: "all",
			post_data: {
				from: moment(new Date()).format("YYYY-MM-DD") + ' 00:00:00',
				to: moment(new Date()).format("YYYY-MM-DD") + ' 00:00:00',
				responseType: "all",
			},
			loading: true
		};
	},
	created() {
		this.fethLogs();
	},
	methods: {
		dateChanged(daterange) {
			this.post_data.from = moment(daterange[0]).format("YYYY-MM-DD HH:mm:ss");
			this.post_data.to = moment(daterange[1]).format("YYYY-MM-DD HH:mm:ss");
			this.fethLogs();
		},
		cleardatepicker() {
			this.daterange = "";
		},

		filterStatus() {
			this.post_data.responseType = this.response_type;
			this.fethLogs();
		},
		fethLogs(page = 1) {
			this.loading = true;
			this.current_page = page;
			this.axios
				.post("/api/integration-gateway-logs/" + page + "/all", this.post_data)
				.then(response => {
					this.logs = response.data.logs.list;
					this.pages = response.data.logs.pages;
					this.loading = false;

				})
				.catch(error => {
					this.loading = false;
					console.log(error);
				});
		},
	}
};
</script>
<style lang="scss">
$theme-color: #06A5ED;

.jobs_table {
	border-top: 5px solid $theme-color;

	table {
		width: 100%;
		border-collapse: collapse;
		border-left: 1px solid #ccc;
		font-size: 14px;
		margin-bottom: 15px;

		tr {
			th {
				background: #004a7c;
				padding: 20px 10px;
				color: #ccc;
				font-weight: 700;
				border: 1px solid #222;
				border-left: 0;
				border-top: 0;
				text-align: left;
			}

			td {
				background: #eee;
				padding: 20px 10px;
				color: #333;
				font-weight: 400;
				border: 1px solid #ccc;
				border-left: 0;
				border-top: 0;
				text-align: left;
				vertical-align: top;

				p {
					padding: 0 0 10px 0;

					b {
						font-weight: 700;
					}
				}

				p.text-center {
					text-align: center;
				}

				span.label {
					display: inline-block;
					font-weight: 700;
					background: #000;
					color: #fff;
					border-radius: 4px;
					padding: 5px;
					text-align: center;
					margin-bottom: 5px;

					&.green {
						background: #46aa42;
					}
				}

				.icons_wrapper {
					padding: 10px 0;
					display: flex;
					justify-content: space-between;
					position: relative;

					a {
						display: inline-block;
						width: 24px;
						height: 24px;

						.material-icons {
							font-size: 18px;
							position: relative;
							top: 0;
							margin: 0;
							font-weight: normal;

							&.blue {
								color: rgb(45, 91, 241);
							}

							&.red {
								color: rgb(241, 74, 45);
							}

							&.green {
								color: #46aa42;
							}

							&.orange {
								color: orange;
							}

							&.grey {
								color: grey;
							}

						}
					}
				}

				.bt {
					width: 120px;
					height: 40px;
					text-align: center;
					line-height: 40px;
					border-radius: 4px;
					display: block;
					margin: 5px 0;
				}

				.bt_black {
					background: #063657;
					color: #fff;
				}

				.bt_gold {
					background: $theme-color;
					color: #000;
				}
			}
		}
	}
}

.two_tabs {
	padding: 40px 10px 0 10px;
	display: flex;
	justify-content: space-around;

	.tab_buttons {
		display: block;
		width: 40%;
		height: 50px;
		text-align: center;
		line-height: 50px;
		background: #ececec;
		color: #333;
		border: 1px solid #ddd;

		span.label {
			display: none;
			background: $theme-color;
			color: #000;
			padding: 5px 10px;
			border-radius: 4px;
		}
	}

	.tab_buttons.active {
		background: #004a7c;
		color: #fff;
	}
}

.view_optons_wrapper {
	width: 240px;
	height: auto;
	background: #fff;
	border: 1px solid #ccc;
	box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
	position: absolute;
	left: -250px;
	top: 10px;
	padding: 10px;

	a.view_option_button {
		width: 100% !important;
		height: 40px !important;
		background: $theme-color;
		color: #000;
		display: block;
		margin-bottom: 10px;
		text-align: center;
		line-height: 40px;
	}

	a.view_option_button:last-child {
		background: #aaa;
		color: #000;

		margin-bottom: 0;
	}
}</style>

